<template>
  <module-container route-name="accounts" show-toolbar :title="$t('accounts')">
    <template #route>
      <router-view />
    </template>

    <template #filters>
      <accounts-filters persistent />
    </template>
  </module-container>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import AccountsFilters from "@/modules/accounts/components/AccountsFilters.vue";
import PaginateProviderMixin from "@/mixins/PaginateProviderMixin";

@Component({
  components: { AccountsFilters },
})
export default class AccountsIndex extends Mixins(PaginateProviderMixin) {}
</script>
