<template>
  <sheet-filters
    v-model="obFilterData"
    :model-name="modelClassName"
    :persistent="persistent"
    @apply="applyFilters"
    @reset="resetFilters"
  >
    <template #default="{ apply }">
      <v-row>
        <v-col cols="12" lg="4">
          <form-field-text
            v-model="obFilterData.account_code"
            :clearable="true"
            label="account.code"
            @save="apply"
            @input:debounce="apply"
            @change:debounce="apply"
          />
        </v-col>
        <v-col cols="12" lg="4">
          <form-field-text
            v-model="obFilterData.name"
            :clearable="true"
            label="name"
            @save="apply"
            @input:debounce="apply"
            @change:debounce="apply"
          />
        </v-col>
        <v-col cols="12" lg="4">
          <payment-method-select
            v-model="obFilterData.paymentmethod_id"
            account
            label="account.type"
            @change="apply"
          />
        </v-col>
      </v-row>
    </template>
  </sheet-filters>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import SheetFilters from "@/components/form/SheetFilters.vue";
import AccountsMixin from "@/modules/accounts/mixins/AccountsMixin";
import { AppModule } from "@/store/app";
import { get } from "lodash";
import PaymentMethodSelect from "@/modules/paymentmethods/components/PaymentMethodSelect.vue";

@Component({
  components: { PaymentMethodSelect, SheetFilters },
})
export default class AccountsFilters extends Mixins(AccountsMixin) {
  @Prop(Boolean) readonly persistent!: boolean;

  obFilterData: Record<string, any> = {};
  sCode: string | null = null;
  sName: string | null = null;

  onMounted() {
    this.obFilterData = get(AppModule.filters, this.modelClassName, {});
  }

  applyFilters() {
    // const obSearch: Record<string, any> = {};
    //
    // if (this.sCode) {
    //   obSearch.code = this.sCode;
    // }
    //
    // if (this.sName) {
    //   obSearch.name = this.sName;
    // }
    //
    // this.obFilterData.search = obSearch;
    this.$emit("apply", this.obFilterData);
  }

  resetFilters() {
    this.$emit("input", {});
    this.$emit("reset");
    this.applyFilters();
  }
}
</script>

<style scoped></style>
